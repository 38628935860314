export const serviceMock = [
  {
    id: 1,
    image: require('assets/cool feature.png'),
    title: 'Cool feature title',
  },
  {
    id: 2,
    image: require('assets/cooler feature.png'),
    title: 'Even cooler feature',
  },
  {
    id: 3,
    image: require('assets/coolest feature.png'),
    title: 'Cool feature title',
  },
];
